import React, { useContext, useEffect, useState } from 'react'
import './payment.css'
import DoneImg from '../../shared/images/payment.png'
import { CircularProgress } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js'
import rideContext from '../../context/RideContext'

function PaymentSuccess() {
  
  return (
    <>
    <div>
      <span className="success-icon-div"><img src={DoneImg} style={{width:"50%"}} alt="payment done" /></span>
      <div className='mb-5'>
      <h3 className='text-center my-1'>Payment Successfull</h3>
      <p className='text-center mb-3'>Ride booking is in process</p>
      <p className='text-center my-2'><CircularProgress sx={{height:"15px", width: "15px", color: "#21b05f"}}/></p>
      <p className='text-center'><strong>Please do not close or reload page.</strong></p>
      </div>
  </div> 
    </>
    
  )
}

export default PaymentSuccess