import React from "react";

const NotFound = () => {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <h1 className="display-4">404</h1>
            <p className="lead">Oops! Page not found.</p>
            <p className="mb-4">The requested page could not be found.</p>
            <a href="/" className="btn btn-primary">
              Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
