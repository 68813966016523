import React from "react";
import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <section className={styles.privacySection}>
      <h1 className={styles.privacyHeading}>Privacy Policy</h1>
      <p className={styles.description}>
      <div className='container p-3' style={{ backgroundColor: "#fff"}}>
            <h4 className={`h4 ${styles.h4}`}>Introduction</h4>
          <p className={`${styles.subDescription}`}>
                Trixi Taxi ("we", "our", or "us") is committed to safeguarding the privacy of our app users. This policy outlines how we collect, use, and disclose your information.</p>

          <h4 className={`h4 ${styles.h4}`}>Information Collection</h4>
            <h5 className={`h5 ${styles.h5}`}>Personal Data</h5>
          <p className={`${styles.subDescription}`}> When you register or use Trixi Taxi, we might collect the following:</p>

            <ol type='number'>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}> Name</strong>: To personalize your user experience.</li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}> Contact Information</strong>: Email address and phone number.</li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}> Location Data</strong>: To assist with taxi routing and location-based services.</li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>  Usage Data</strong>: Routes taken, trip durations, and frequency.</li>
            </ol>

            <h5 className={`h5 ${styles.h4}`}>Usage and Log Data:</h5>
          <p className={`${styles.subDescription}`}>We may also collect data from the device you’re using to access our app, such as IP address, browser type, and device identifiers.</p>


            <h4 className={`h5 ${styles.h5}`}>How We Use Your Data</h4>
            <ol type='number'>
            <li className={`${styles.subDescription}`}><strong>Provide Services</strong>: To facilitate the core functions of the Trixi Taxi app.</li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Improvements</strong>: To enhance app functionality and overall user experience.</li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Communication</strong>: To send you updates, notifications, and necessary information related to the app.</li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Legal Obligations</strong>: To comply with legal obligations and protect our users' rights.</li>
            </ol>


            <h4 className={`h4 ${styles.h4}`}>Data Sharing and Disclosure</h4>
          <p className={`${styles.subDescription}`}> We will not sell your personal data to third parties. However, we may share your data:</p>
           <ol type='number'>
            <li className={`${styles.subDescription}`}>To comply with legal processes.</li>
            <li className={`${styles.subDescription}`}>To provide certain app functionalities.</li>
            <li className={`${styles.subDescription}`}>If we undergo business transitions, like mergers or acquisitions.</li>
           </ol>

            <h4 className={`h4 ${styles.h4}`}>Data Storage and Security</h4>
          <p className={`${styles.subDescription}`}>Your data will be stored securely on servers with protective measures in place. While we aim to protect your personal data, no method is 100% secure.
            </p>

          <h4 className={`h4 ${styles.h4}`}>Children's Privacy</h4>

          <p className={`${styles.subDescription}`}> Our app is not intended for users under the age of 13. We do not knowingly collect personal data from children under 13.</p>

            <h4 className={`h4 ${styles.h4}`}>Changes to This Policy</h4>

          <p className={`${styles.subDescription}`}>We might periodically update this policy. Please check this space to stay updated.</p>

            <h4 className={`h4 ${styles.h4}`}>Your Rights</h4>
          <p className={`${styles.subDescription}`}>
                You have the right to:</p>

                <ol>
            <li className={`${styles.subDescription}`}>Access your personal data.</li>
            <li className={`${styles.subDescription}`}>Update any incorrect or outdated data.</li>
            <li className={`${styles.subDescription}`}>Request deletion of your data.</li>
                </ol>
          <p className={`${styles.subDescription}`}>To exercise any of these rights, please contact us.</p>
        </div>
      </p>
    </section>
  );
};

export default Privacy;