import React from "react";
import styles from "./Cars.module.css";
import trixi from "../../shared/images/trixi.png"
import premium from "../../shared/images/premium.png"
import xl from "../../shared/images/xl.png"
import electric from "../../shared/images/electric.png"
import { useTranslation } from "react-i18next";

const Cars = () => {
  const { t, i18ns } = useTranslation();
    const carData = [
        {
            "name": "Trixi",
            "image":trixi,
            "seat":"4 Seats",
            "description":"Our Reliable And Efficient Cabs Are Perfect For Quick And Convenient Rides, Ensuring You Reach Your Destination Comfortably.",
        },
        {
            "name": "Electric",
            "image":electric,
            "seat":"4 Seats",
            "description":"Embrace Eco-Friendly Travel With Our Electric Cars. Enjoy A Smooth And Quiet Ride While Reducing Your Carbon Footprint.",
        },
        {
            "name": "XL",
            "image":xl,
            "seat":"7 Seats",
            "description":"Traveling with a group or need space? Our XL SUVs offer ample room for passengers and luggage for a comfortable journey.",
        },
        {
            "name": "Premium",
            "image":premium,
            "seat":"4 Seats",
            "description":"Elevate Your Journey In Style With Our Premium Sedans. Experience Luxury And Sophistication, Making Every Trip Memorable.",
        },
    ]
  return (
    <section className={`${styles.carSection}`} >
      <h1 className={`text-center mb-3 ${styles.carHeading}`}>{t("Our Cars")}</h1>
      <h2 className={`text-center mb-5 ${styles.carHeading1}`}>
        {t(`At Trixi Taxi, we understand that every journey is unique. That's why we proudly offer a fleet tailored to your preferences:`)}
      </h2>
      <div className={`${styles.grid}`}>
        {carData.map((data, i) => {
          return (
            <div className={`${styles.gridItem} px-3 mb-3`} key={i}>
              <div className={`card  ${styles.carCards}`}>
                <div className="card-body text-center p-4">
                    <img src={data.image} className={styles.carImg} alt="" />
                    <h3 className={styles.carDataHead}>{t(data.name)}</h3>
                    <h4 className={styles.carSeats}>{t(data.seat)}</h4>
                    <p className={styles.carDescription}>{t(data.description)}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <p className="pt-5 mb-0 text-center">
      {t("No matter your choice, safety, reliability, and exceptional service are at the core of our offerings. Your journey, your way!")}
      </p>
    </section>
  );
};

export default Cars;
