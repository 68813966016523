import React, { useContext, useState, useEffect } from "react";
import { PaymentLoader } from "../Loader/PaymentLoader";
import { loadStripe } from '@stripe/stripe-js';
import { baseURL } from '../../config';
import {Elements} from '@stripe/react-stripe-js';
import rideContext from "../../context/RideContext";
import CardMethod from "./CardMethod";
import axios from "axios";


const stripePromise = loadStripe('pk_test_51OLSSkDzaeDL8jWuNrMWNkM5UMIOrVr1UpO1XgDP1URMxnLSJBoS3MIQBiLdPNcncXeVRDShf5IXg3cU1MHzCe8200sXGYV8gK');


const CardPayment = () => {
  const {price} = useContext(rideContext)
  const [ clientSecret, setClientSecret ] = useState('');
  const [sessionCreated, setSessionCreated] = useState(false)
  const [loading, setLoading] = useState(false)
  const appearance = {
    theme: 'flat',
    variables: { colorPrimaryText: '#262626' }
  };
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if(!sessionCreated){
      handlePayment()
    }
  }, [sessionCreated]);

  const handlePayment = async()=>{
    console.log("called")
    const pdata = {
          email: localStorage.getItem('A_email'),
          price: Math.ceil(price),
      };

    const sessionKey = localStorage.getItem("Key");
    setLoading(true)
    const url = `${baseURL}/create-checkout-session/`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionKey}`,
      },
    };
    try {
      const resp = await axios
        .post(url, JSON.stringify(pdata), options)
        .then((response) => {
          return response;
        });
        setLoading(false)
        setSessionCreated(true)
        setClientSecret(resp.data.clientSecret);
    } catch (error) {
      setLoading(false)
      if (error.response && error.response.data && error.response.data.msg) {
        const errorMessage = error.response.data.msg;
        console.log(errorMessage);
      }
    }
}
  return (
    <>
    {loading?
    
    <PaymentLoader />:
    <div>
    {clientSecret && stripePromise && (
      <Elements stripe={stripePromise} options={{ clientSecret, appearance}}>
        <CardMethod />
      </Elements>
    )}
  </div>}
    </>
    
  );
};

export default CardPayment;
