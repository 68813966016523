import React from "react";

const RefundModal = ({showModel, handleCloseModel}) => {
    return (
        <>{
            showModel.show? <div class="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: "block", paddingRight: "14.9921px", background:"#1f1e1e73" }} aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Refund Status</h5>
                    </div>
                    <div class="modal-body">

                    <div className="my-3">
                        <div className="mb-3">Your refund has been processed successfully.</div>
                        <div>Refund Amount: <span className="text-secondary">{showModel.refund_amount}</span> </div>
                        <div >Refund ID: <span className="text-secondary">{showModel.id}</span> </div>
                        <div className="mt-3">Please allow 5-10 business days for the transaction to appear in your account.</div>
                      </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={() => handleCloseModel()} class="btn btn-outline-primary btn-small btn-sm">Close</button>
                    </div>
                </div>
            </div>
        </div>:null
        }
           
        </>
    );
};

export default RefundModal;