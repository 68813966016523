import { Stack } from "@mui/material";
import Cars from "../components/Cars/Cars";
import Download from "../components/Download/Download";
import Footer from "../components/Footer/Footer";
import Login from "../components/signup/Login";

const LoginPage = () => {
  return (
    <>
        <Stack>
          <Login />
          <Cars />
          <Download />
          <Footer />
        </Stack>
    </>
  );
};

export default LoginPage;
