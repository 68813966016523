import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    <section className={styles.aboutSection}>
      <h1 className={styles.aboutHeading}>About Us</h1>
      <p className={styles.description}>
        Welcome to Trixi Taxi, the premier taxi service in Sweden. We are
        dedicated to providing safe, reliable, and convenient transportation to
        our customers. Our team of experienced drivers and customer service
        representatives are committed to making your taxi experience a pleasant
        one.At Trixi Taxi, we understand that getting to your destination on
        time is crucial. That's why we use the latest technology to ensure that
        our drivers are always on time and know the best routes to get you to
        your destination quickly and efficiently.Our fleet of vehicles is
        well-maintained and regularly serviced to ensure your safety and
        comfort. We offer a range of vehicles to suit your needs, from sedans to
        SUVs and vans, so whether you're traveling alone or with a group, we
        have the perfect vehicle for you. At Trixi Taxi, we believe in providing
        excellent customer service. Our friendly and professional customer
        service representatives are available 24/7 to assist you with any
        questions or concerns you may have. We pride ourselves on our commitment
        to customer satisfaction and strive to exceed your expectations every
        time you use our service. Thank you for choosing Trixi Taxi for your
        transportation needs. We look forward to serving you!
      </p>
    </section>
  );
};

export default About;
