import React, { useState, useEffect, useRef } from 'react'
import './profile.css'
import faceIcon from '../../shared/images/face1.jpg'
import { CircularProgress, TextField } from '@mui/material'
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'
import BorderColorIcon from '@mui/icons-material/BorderColor';


const ProfileContainer = () => {
    const [data, setData] = useState(null)
    const sessionKey = localStorage.getItem("Key");
    const [IsLoading, setIsLoading] = useState(false);
    const [BtnIsLoading, setBtnIsLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const userType = localStorage.getItem('A_usertype')
    const stockholmDate = (date) => {
        const formattedDate = new Date(date).toLocaleString();
        return formattedDate;
    };

    const handleUserDetails = async () => {
        setIsLoading(true);
        try {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}/get-profile/`, {
                    headers: {
                        Authorization: "Bearer " + sessionKey,
                    },
                })
                .then((response) => {
                    setData(response.data.data);
                    setEmail(response.data.data.email)
                    setProfileImage(response.data.data.profile_image)
                    setFirstName(response.data.data.first_name)
                    setLastName(response.data.data.last_name)
                    setAddress(response.data.data.address)
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error:", error.message);
                });
        } catch (error) {
            console.log("Error", error);
        }
    };

    useEffect(() => {
        handleUserDetails();
    }, []);


    const updateUserData = async () => {
        setBtnIsLoading(true)
        const dObj = {
            'email': email,
            'first_name': firstName,
            'last_name': lastName,
            'address': address
        }
        try {
            axios.put(
                `${process.env.REACT_APP_BASE_URL}/get-profile/`, dObj,
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionKey
                    }
                }
            ).then(
                (response) => {
                    toast("Updated Successfully")
                    localStorage.setItem("A_phone", phone);
                    localStorage.setItem("A_firstname", firstName);
                    localStorage.setItem("A_lastname", lastName);
                    localStorage.setItem("A_email", email);
                    setBtnIsLoading(false)
                }
            )

        } catch (error) {
            setBtnIsLoading(false)
            console.log(error)
        }
    }


    const fileInputRef = useRef(null);

    // Function to trigger file input click event
    const handleUploadDivClick = () => {
        fileInputRef.current.click();
    };

    // Function to handle file selection
    const hadleImageUpload = (event) => {
        setIsLoading(true)
        const selectedFile = event.target.files[0];
        let data = new FormData();
        data.append("profile_image", selectedFile);
        try {
            axios.put(
                `${process.env.REACT_APP_BASE_URL}/update-profile-image/`, data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionKey,
                        "Content-Type": "multipart/form-data"
                    }
                }
            ).then(
                (response) => {
                    toast("Image Updated Successfully")
                    console.log(response.data.profile_image)
                    setProfileImage(response.data.profile_image)
                    setIsLoading(false)
                }
            )

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    };



    return (
        <div>
            {!data ?
                <div className="my-5 py-5 text-center">
                    <CircularProgress />
                </div> :
                <div className="w-50 mx-auto my-5">
                    <div className="card card-body shadow py-5" style={{ border: "none" }}>
                        <div class="col-12">
                            {IsLoading ?
                                <div className="my-5 py-5 text-center">
                                    <CircularProgress />
                                </div>
                                :
                                <form class="form-sample py-2 px-4">
                                    <div class="row mb-3">
                                        <div class="col-md-6  d-flex justify-content-center align-items-center" style={{ flexDirection: "column" }}>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => hadleImageUpload(e)}
                                            />
                                            <div className="nav-profile-image mb-2 text-center" onClick={() => handleUploadDivClick()}>
                                                <img src={profileImage} className='profile-img mb-2' alt="profile" />
                                                <div class="overlay">
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <span className="text-light">
                                                            <BorderColorIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nav-profile-text text-center d-flex flex-column">
                                                <span className="font-weight-bold">{data.first_name} {data.last_name}</span>
                                                <span className="text-secondary text-small" style={{ fontSize: ".8rem" }}>
                                                    {stockholmDate(data.date_joined)}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">

                                            <div className="form-group mb-3">
                                                <PhoneInput
                                                    className="form-control"
                                                    defaultCountry="SE"
                                                    value={data.phone}
                                                    onChange={setPhone}
                                                    limitMaxLength={true}
                                                    maxLength={16}
                                                    placeholder={"Phone Number"}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} id="email" className={`form-control  form-text`} />
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="text" placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} id="fname" className={`form-control  form-text`} />
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="text" placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} id="lname" className={`form-control  form-text`} />
                                            </div>

                                        </div>
                                    </div>



                                    {userType === "END_USER" && <>
                                        {BtnIsLoading ?
                                            <div className="d-flex justify-content-center mt-5">
                                                <button type="button" class="btn btn-primary btn-sm" disabled><CircularProgress className='text-light' style={{ fontSize: ".2rem", width: "19px", height: "19px" }} /></button>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center mt-5" onClick={(e) => updateUserData()}>
                                                <button type="button" class="btn btn-primary btn-sm">Update</button>
                                            </div>}
                                    </>}



                                </form>}
                        </div >
                    </div>
                </div>
            }

        </div>

    )
}

export default ProfileContainer