import { Stack } from "@mui/material";
import Hero from "../components/Hero/Hero";
import Cars from "../components/Cars/Cars";
import Download from "../components/Download/Download";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
        <Stack>
          <Hero />
          <Cars />
          <Download />
          <Footer />
        </Stack>
    </>
  );
};

export default Home;
