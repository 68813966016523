import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../components/notFound/NotFound";
import Wrapper from "../components/wrapper/Wrapper";
import AboutPage from "../pages/About";
import PrivacyPage from "../pages/Privacy";
import Register from "../pages/Register";
import LoginPage from "../pages/Login";
import MyRidesPage from "../pages/MyRides";
import ContactPage from "../pages/Contact";
import LiveTracking from "../pages/LiveTracking";
import ProfilePage from "../pages/Profile";
import Complete from "../pages/Complete";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Wrapper />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="" element={<ProtectedRoute />}>
          <Route path="/my-rides" element={<MyRidesPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/payment-sucessfull" element={<Complete />} />
        </Route>
      </Route>
      <Route path="/live-tracking/:rideId" element={<LiveTracking />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
