import { Stack } from "@mui/material";
import Footer from "../components/Footer/Footer";
import About from "../components/About/About";

const AboutPage = () => {
  return (
    <>
      <Stack>
        <About />
        <Footer />
      </Stack>
    </>
  );
};

export default AboutPage;
