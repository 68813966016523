import React from "react";
import { Outlet, useLocation } from "react-router-dom/dist";
import { Box } from "@mui/material";
import Navbar from "../Navbar/Navbar";


const Wrapper = () => {
  return (
    <Box>
      <Navbar />
      <Box>
        <Outlet />
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default Wrapper;
