import React, { useState, useEffect } from 'react'
import { baseURL, googleMapApiKey, baseSocketURL } from '../config';
import { useJsApiLoader } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { DateTime } from 'luxon';
import Review from '../components/Review/Review';
import Navbar from '../components/Navbar/Navbar';
import UserLocationComponent from '../components/LiveTracking/UserLocationComponent';


const LiveTracking = () => {
  const [rideData, setRideData] = useState(null)
  const [isRide, setIsRide] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const { rideId } = useParams()
  const userKey = localStorage.getItem("key")
  const getRideData = async () => {
    try {
      const resp = await axios.get(
        `${baseURL}/get-ride/${rideId}`,
      ).then(
        function (response) {
          if (response.data.length !== 0) {
            setRideData(response.data[0])
          }
          return response.data;

        }
      )
    } catch (error) {
      console.log(error, 'err')
    }
  }

  const stockholmNow = DateTime.now().setZone('Europe/Stockholm');
  let rideDate = DateTime.now().setZone('Europe/Stockholm');

  if (rideData) {
    rideDate = DateTime.fromISO(rideData.ride_date, { zone: 'Europe/Stockholm' });
  }


  useEffect(() => {
    if (userKey) {
      let reviewSocket;
      reviewSocket = new WebSocket(`${baseSocketURL}/ws/review_listener/${rideId}`);
      console.log("Review WebSocket connected");

      const handleSocketMessage = (event) => {
        const data = JSON.parse(event.data);
        setReviewData(data)
        console.log(data, "review socket data")
      };

      reviewSocket.addEventListener('message', handleSocketMessage);

      return () => {
        console.log("Review WebSocket disconnected");
        reviewSocket.removeEventListener('message', handleSocketMessage);
        reviewSocket.close();
      };
    }
  }, [socketConnected]);


  useEffect(() => {
    getRideData()
  }, [isRide])


  return (
    <>
      {reviewData && <Review openModal={true} rideId={rideId} />}
      {
        rideData && (rideDate.diff(stockholmNow, 'minutes').minutes > 15 || rideData.status === "Completed" || rideData.status === "Customer Cancelled") ? <h5 className='d-flex justify-content-center align-items-center' style={{ height: "90vh" }}>
          The provided link has expired
        </h5> : <div>
          <Navbar />
          <div className="" style={{ height: "95vh" }}>
            <UserLocationComponent rideId={rideId} rideData={rideData} />
          </div>
        </div>
      }

    </>
  )
}

export default LiveTracking