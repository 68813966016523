import React, { memo, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
  useJsApiLoader,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import axios from "axios";
import carIcon from "../../shared/images/premium.png";
import { googleMapApiKey, baseURL } from "../../config";
import io from "socket.io-client";

const socket = io.connect(`${baseURL}/socket`);

const containerStyle = {
  width: "100%",
  height: "100%",
};

const UserLocationComponent = ({ rideId, rideData }) => {
  const [socketConnected, setSocketConnected] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 59.3293, lng: 18.0686 });
  const [driverLocations, setDriverLocations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("A_userid");
  const [selectedMarker, setSelectedMarker] = useState(false);
  const [rideStatus, setRideStatus] = useState(null);
  const [directions, setDirections] = useState(null);
  const [driverDirections, setDriverDirections] = useState(null);
  const [error, setError] = useState(null);

  const handleMarkerClick = () => {
    setSelectedMarker(true);
  };

  const fetchDriverDirections = () => {
    if (!driverLocations || !rideData) return;

    const origin = {
      lat: parseFloat(driverLocations.latitude),
      lng: parseFloat(driverLocations.longitude),
    };
    const destination = rideData.drop_location_name;
    const waypoints = [];

    waypoints.push({
      location: rideData.picup_location_name,
      stopover: true,
    });

    if (rideData.stops[0].location_name) {
      waypoints.push({
        location: rideData.stops[0].location_name,
        stopover: true,
      });
    }

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDriverDirections(result);
        } else {
          setError(result);
        }
      }
    );
  };

  const fetchDirections = () => {
    if (!rideData) return;

    const origin = rideData.picup_location_name;
    const destination = rideData.drop_location_name;
    const waypoints = [];

    if (rideData.stops[0].location_name) {
      waypoints.push({
        location: rideData.stops[0].location_name,
        stopover: true,
      });
    }

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          setError(result);
        }
      }
    );
  };

  useEffect(() => {
    fetchDirections();
    fetchDriverDirections();
  }, [rideData, driverLocations, socketConnected]);

  useEffect(() => {
    const handleConnect = () => {
      socket.emit("joinRoom", rideId);
    };

    const handleDisconnect = () => {
      console.log("Disconnected from socket");
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, [socket]);

  useEffect(() => {
    const handleSocketMessage = (data) => {
      setMapCenter({
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude),
      });
      setDriverLocations(data);
    };

    socket.on("message", handleSocketMessage);

    return () => {
      socket.off("message", handleSocketMessage);
    };
  }, []);

  const renderMap = () => {
    // if (typeof window.google === 'undefined') return null
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={15}
      >
        {driverDirections && (
          <DirectionsRenderer
            directions={driverDirections}
            options={{ suppressMarkers: true, preserveViewport: true }}
          />
        )}
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              preserveViewport: true,
              polylineOptions: { strokeColor: "#00000000" },
            }}
          />
        )}
        {driverLocations ? (
          <>
            <Marker
              position={{
                lat: parseFloat(driverLocations.latitude),
                lng: parseFloat(driverLocations.longitude),
              }}
              onClick={() => handleMarkerClick()}
              icon={{
                url: carIcon,
                scaledSize: new window.google.maps.Size(60, 30),
              }}
            />
            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: parseFloat(driverLocations.latitude),
                  lng: parseFloat(driverLocations.longitude),
                }}
                onCloseClick={() => setSelectedMarker(false)}
              >
                <div>
                  <p>Driver Name: {driverLocations.driver_name}</p>
                </div>
              </InfoWindow>
            )}
          </>
        ) : null}
      </GoogleMap>
    );
  };

  return (
    <>
      <LoadScript googleMapsApiKey={googleMapApiKey}>
        {isLoading ? <h5>Loading...</h5> : renderMap()}
      </LoadScript>
    </>
  );
};

export default memo(UserLocationComponent);
