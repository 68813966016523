import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import isTokenExpired from './isTokenExpired'

const ProtectedRoute = () => {
    const authKey = localStorage.getItem('Key')
    const tokenExpired = isTokenExpired(authKey)
    const navigate = useNavigate()

    useEffect(() => {
        !authKey && navigate('/login')
    }, [authKey])

    useEffect(() => {
        if (tokenExpired) {
            localStorage.removeItem("Key")
            navigate('/login')
        }
    }, [tokenExpired, navigate])

    return authKey ? <Outlet /> : <>{navigate('/')}</>
}

export default ProtectedRoute
