import { Stack } from "@mui/material";
import Footer from "../components/Footer/Footer";
import Privacy from "../components/Privacy/Privacy";

const PrivacyPage = () => {
  return (
    <>
      <Stack>
        <Privacy />
        <Footer />
      </Stack>
    </>
  );
};

export default PrivacyPage;
