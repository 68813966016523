import React from "react";
import styles from "./Hero.module.css";
import heroBg from "../../shared/images/home.png";
import RideBook from "../RideBook/RideBook";
import RideProvider from "../../context/RideProvider";
import Login from "../signup/Login";
import { useTranslation } from "react-i18next";
import heroImgTwo from "../../shared/images/heroImgTwo.png";


const Hero = () => {
  const { t, i18ns } = useTranslation();

  const key = localStorage.getItem("Key")
  console.log(key)

  return (
    <section>
      <img src={heroImgTwo} className='appImg' alt="appImg" />
    </section>
    // <div className={styles.container}> 
    //    <div className={`${styles.textContainer}`}>
    //         {/* <div style={{ display: "inline-block" }}>
    //       <h1 className={`text-light mb-3  ${styles.heading1}`}>
    //             {t("We offer the best prices in Sweden")}
    //           </h1>
    //           <h2 className={`text-light ${styles.heading2}`}>
    //             {t(`Welcome to Trixi Taxi, the premier taxi service in Sweden. We are dedicated to providing safe, reliable, and convenient transportation to our customers. Our team of experienced drivers and customer service representatives are committed to making your taxi experience a pleasant one.`)}
    //           </h2>
    //         </div> */}
    //       </div>
    //    {/* <div className={styles.heroGrid}>
         
    //       <div className={`card ${styles.cardContainer}`}>
    //           <div className="card-body">
    //             <RideProvider>
    //               {<RideBook /> }
                  
    //             </RideProvider>
    //           </div>
    //         </div>
    //     </div> */}
    // </div>
  );
};

export default Hero;