import React from 'react'
import './Footer.css'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import appImg from "../../shared/images/footer.png"
import logo from "../../shared/images/finallogo.png";

import fbLogo from "../../shared/images/facebook-logo.png";
import igLogo from "../../shared/images/Instagram-logo.png";



const Footer = () => {
  const { t, i18n } = useTranslation();
  const nav = useNavigate()
  return (
    <footer>
      <img src={appImg} className='appImg' alt="appImg" />
      <div className="text-center text-md-start p-3" style={{marginLeft:'-2rem'}}>
        <div className="footer-wrap">
          <div className="about" style={{textAlign:'center',justifyContent:'center',alignItems:'center'}}>
            <img src={logo} alt="finalLogo" width={70} height={70}/>
            <p className=" py-1" >Info@trixitaxi.com</p>
            <p className="">
              <p className="mb-0">Stockholm, Sweden</p>
            </p>
            <img  className="me-2 text-reset" src = {fbLogo} alt='FaceBook Logo' height={30} width={30}/>
            <img  className="me-2 text-reset" src = {igLogo} alt='InstaGram Logo'  height={30} width={30}/>

              {/* <a href="" className="me-2 text-reset"><FacebookIcon /></a>
              <a href="" className="me-2 text-reset"><InstagramIcon /></a> */}
           
          </div>

          {/* <div className="company">
            <h6 className="fw-bold">App</h6>
            <p><a href="https://play.google.com/store/apps/details?id=com.trixitaxi&pli=1" target="_blank">{t("Download App")}</a></p>
            <p><a href="#">FAQ</a></p>
          </div> */}

          <div className="company" style={{textAlign:'center',marginLeft:'-2rem'}}>
            <p className='py-1'>{t("Foretagsinlogging")}</p>
            <p className='py-1'>{t("Ladda ner appen")}</p>
            <p className='py-1'>{t("Chat med oss")}</p>
            <p className='py-1'>{t("FAQ")}</p>
          </div>
          <div className="company " style={{textAlign:'center'}}>
            <p className='py-1'>{t("Om oss")}</p>
            <p className='py-1'>{t("Kontakta oss")}</p>
            <p className='py-1'>{t("Anvandervillkor")}</p>
            <p className='py-1'>{t("Integritetspolicy")}</p>
          </div>

          {/* <div className="company">
            <h6 className="fw-bold">User</h6>
            <p><a href="#" onClick={() => nav('/signup')}>{t("Register")}</a></p>
            <p><a href="#" onClick={() => nav('/signin')}>{t("Login")}</a></p>
            <p><a href="https://company.trixitaxi.com/" target="_blank">{t("Company Login")}</a></p>
            <p><a href="#" onClick={() => nav('/about')}>{t("About Us")}</a></p>
            <p><a href="#" onClick={() => nav('/privacypolicy')}>{t("Contact Us")}</a></p>
          </div> */}

        

          {/* <div className="useful-links">
            <h6 className="fw-bold">{t("Useful Links")}</h6>
            <p><a href="#">{t("Terms of Services")}</a></p>
            <p><a href="#" onClick={() => nav('/privacypolicy')}>{t("Privacy Policy")}</a></p>
          </div> */}
        </div>
      </div>

    </footer>
  )
}

export default Footer