import React from "react";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (e, pageNumber) => {
    onPageChange(pageNumber);
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-3 mb-5">
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Stack>
      </div>

    </>
  );
};

export default PaginationComponent;
