import React from "react";

const CancelRideModal = ({showModel, onHandleDelete, handleCloseModel}) => {
    return (
        <>{
            showModel.show? <div class="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: "block", paddingRight: "14.9921px", background:"#1f1e1e73" }} aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    </div>
                    <div class="modal-body">
                        <div className="mb-3">
                            <p className="mb-1">If you cancel your ride within 1 hour of the scheduled time, a cancellation fee of 75 SEK plus applicable charges will apply. Otherwise, only applicable charges will be incurred.</p>
                            <span className="text-secondary">Your refund will be processed within 5-10 business days.</span>
                        </div>
                    <p>Are you sure you want to cancel your ride?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={() => handleCloseModel()} class="btn btn-outline-primary btn-small btn-sm">Close</button>
                        <button type="button" onClick={() => onHandleDelete(showModel.id)} class="btn btn-primary btn-sm">Cancel Ride</button>
                    </div>
                </div>
            </div>
        </div>:null
        }
           
        </>
    );
};

export default CancelRideModal;