import { Stack } from "@mui/material";
import React from "react";
import Profile from "../components/profile/Profile";
import Footer from "../components/Footer/Footer";

const ProfilePage = () => {
  return (
    <Stack>
      <Profile />
      <Footer />
    </Stack>
  );
};

export default ProfilePage;
