import React from "react";
import styles from "../components/Hero/Hero.module.css";
import heroBg from "../shared/images/home.png";
import PaymentSuccess from "../components/Payment/PaymentSuccess";


const Complete = () => {

  const key = localStorage.getItem("Key")
  console.log(key)
  return (
    <div className={styles.container}>
      <img className={styles.image} src={heroBg} alt="HeroBg" />
      <div className={styles.overlay}>
        <div className="row">
          <div className={`col-lg-6 ${styles.textContainer}`}>
            <div style={{ display: "inline-block" }}>
              <h1 className={`text-light mb-3  ${styles.heading1}`}>
                We offer the best prices in Sweden
              </h1>
              <h2 className={`text-light ${styles.heading2}`}>
                Welcome to Trixi Taxi, the premier taxi service in Sweden. We
                are dedicated to providing safe, reliable, and convenient
                transportation to our customers. Our team of experienced drivers
                and customer service representatives are committed to making
                your taxi experience a pleasant one.
              </h2>
            </div>
          </div>
          <div className="col-lg-6">
            <div className={`card ${styles.cardContainer}`}>
              <div className="card-body">
                <PaymentSuccess />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
