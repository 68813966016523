import React, { useState, useEffect, useCallback } from "react";
import {
    GoogleMap,
    DirectionsRenderer,
    useJsApiLoader,
} from "@react-google-maps/api";
import { googleMapApiKey } from "../../config";

const API_KEY = googleMapApiKey;
const DEFAULT_CONTAINER_STYLE = { width: "100%", height: "300px" };

const GoogleMapComponent = React.memo(({ containerStyle, directions }) => {
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            center={{ lat: 37.7749, lng: -122.4194 }}
        >
            {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
    );
});

const DirectionsServiceComponent = React.memo(
    ({ destination, origin, travelMode, via, directionsCallback }) => {
        useEffect(() => {
            if (origin && destination) {
                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route(
                    {
                        destination,
                        origin,
                        travelMode,
                        ...(via ? { waypoints: [{ location: via }] } : {}),
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            directionsCallback(result);
                        }
                    }
                );
            }
        }, [destination, origin, travelMode, via, directionsCallback]);

        return null;
    }
);

function MapComponent({
    containerStyle = DEFAULT_CONTAINER_STYLE,
    onDirectionsLoadCallback,
    travelMode = "DRIVING",
    origin,
    destination,
    via,
    setRideResult,
}) {
    const [directions, setDirections] = useState(null);

    useEffect(() => {
        setDirections(null);
    }, [origin, destination, via]);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: API_KEY,
        libraries: ["places"],
    });

    const directionsCallback = useCallback(
        (result) => {
            if (result !== null && result.status === "OK") {
                setRideResult && setRideResult(result.routes[0].legs[0])
                setDirections(result);
                onDirectionsLoadCallback && onDirectionsLoadCallback(result);
            }
        },
        [onDirectionsLoadCallback]
    );

    if (!origin || !destination) {
        return null;
    }

    return isLoaded ? (
        <>
            <GoogleMapComponent containerStyle={containerStyle} directions={directions} />
            <DirectionsServiceComponent
                destination={destination}
                origin={origin}
                travelMode={travelMode}
                via={via}
                directionsCallback={directionsCallback}
            />
        </>
    ) : null;
}

export default React.memo(MapComponent);
