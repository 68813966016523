import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../Pagination";
import MapInfoModal from "../map/MapInfoModel";
import CancelRideModal from "../CancelRideModal";
import RefundModal from "../RefundModal";
import { baseURL } from "../../config";
// import FilterForm from "../../filter/FilterForm";
// import { queryParams } from "../../../shared/utils";
// import MapInfoModal from "../../map/MapInfoModal";

const MyRide = () => {
  const [rideseData, setRidesData] = useState({ results: [] });
  const [IsLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pcurrentPage, setpCurrentPage] = useState(1);
  const [cCurrentPage, setcCurrentPage] = useState(1);
  const [status, setStatus] = useState("upcoming");
  const [cancleRide, setCancelRide] = useState(false);
  const [orderBy, setOrderBy] = useState("-ride_date");
  const [showDriverAssignDetails, setshowDriverAssignDetails] = useState(false);
  const [showCompanyAssignDetails, setshowCompanyAssignDetails] =
    useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [rideInfo, setMapRideInfo] = useState({
    origin: null,
    destination: null,
    via: null,
  });
  const [showModel, setShowModel] = useState({
    show: false,
    id: null,
  });
  const [refundshowModel, setRefundshowModel] = useState({
    show: false,
    id: null,
    ride_amount: null,
  });

  const handleShowModel = (id) => {
    setShowModel({
      show: true,
      id: id,
    });
  };
  const handleCloseModel = () => {
    setShowModel({
      show: false,
      id: null,
    });
  };

  const refundHandleShowModel = (id, refund_amount) => {
    setRefundshowModel({
      show: true,
      id: id,
      refund_amount: refund_amount,
    });
  };

  const refundHandleCloseModel = () => {
    setRefundshowModel({
      show: false,
      id: null,
      ride_amount: null,
    });
  };

  const stockholmDate = (date) => {
    const formattedDate = new Date(date).toLocaleString();
    return formattedDate;
  };

  const accessToken = localStorage.getItem("Key");

  const handleShowDriverAssignDetails = () => {
    setshowDriverAssignDetails(true);
    setshowCompanyAssignDetails(false);
  };
  const handleShowCompanyAssignDetails = () => {
    setshowDriverAssignDetails(false);
    setshowCompanyAssignDetails(true);
  };

  const nav = useNavigate();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlpreviousPageChange = (pageNumber) => {
    setpCurrentPage(pageNumber);
  };
  const handlCancelPageChange = (pageNumber) => {
    setcCurrentPage(pageNumber);
  };

  const handleGetRidesData = async () => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem("Key");
      const resp = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/my-rides/?paginate=true&page=${currentPage}&ride=${status}&order_by=${orderBy}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          let results = response.data;
          console.log(results);
          setRidesData(results);
          return response.data;
        });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelRide = async (id) => {
    try {
      setIsLoading(true);
      handleCloseModel();
      const accessToken = localStorage.getItem("Key");
      const data = {
        status: "Customer Cancelled",
      };
      const resp = await axios
        .put(`${process.env.REACT_APP_BASE_URL}/ride-status/${id}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          if (response.data.data.status === "Customer Cancelled") {
            toast("Successfully Cancelled Ride");
            setCancelRide(true);
          }
          return response;
        });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpcomingRides = () => {
    setStatus("upcoming");
  };
  const handlePreviousRides = () => {
    setStatus("previous");
  };
  const handleCancelledRides = () => {
    setStatus("cancelled");
  };

  useEffect(() => {
    handleGetRidesData();
  }, [currentPage, pcurrentPage, status, cancleRide, orderBy, cCurrentPage]);

  const handleSortRideDate = () => {
    if (orderBy === "-ride_date") {
      setOrderBy("ride_date");
    } else {
      setOrderBy("-ride_date");
    }
  };
  const handleSortBookDate = () => {
    if (orderBy === "-created_date") {
      setOrderBy("created_date");
    } else {
      setOrderBy("-created_date");
    }
  };

  const handleMapOpen = (ride) => {
    setMapOpen(true);
    setMapRideInfo({
      origin: ride.pickup_location,
      destination: ride.drop_location,
      via: ride.stops[0].location_name,
    });
  };

  return (
    <div className="container">
      <CancelRideModal
        showModel={showModel}
        onHandleDelete={handleCancelRide}
        handleCloseModel={handleCloseModel}
      ></CancelRideModal>
      <MapInfoModal
        isOpen={mapOpen}
        onClose={() => setMapOpen(false)}
        title="Route"
        origin={rideInfo.origin}
        destination={rideInfo.destination}
        via={rideInfo.via}
      />
      <RefundModal
        showModel={refundshowModel}
        handleCloseModel={refundHandleCloseModel}
      ></RefundModal>

      <div className="d-flex justify-content-start my-5">
        <button
          className={`${
            status === "upcoming"
              ? "btn btn-primary btn-sm me-3"
              : "btn btn-outline-primary btn-sm btn-small me-3"
          }`}
          disabled={status === "upcoming" ? true : false}
          onClick={handleUpcomingRides}
        >
          Upcoming Rides
        </button>
        <button
          className={`${
            status === "previous"
              ? "btn btn-primary me-3 btn-sm"
              : "btn btn-outline-primary btn-small me-3 btn-sm"
          }`}
          disabled={status === "previous" ? true : false}
          onClick={handlePreviousRides}
        >
          Previous Rides
        </button>
        <button
          className={`${
            status === "cancelled"
              ? "btn btn-primary btn-sm me-3"
              : "btn btn-outline-primary btn-sm btn-small me-3"
          }`}
          disabled={status === "cancelled" ? true : false}
          onClick={handleCancelledRides}
        >
          Cancelled Rides
        </button>
      </div>

      {IsLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
      ) : (
        <div className="mb-5">
          {rideseData.results.length !== 0 ? (
            <>
              {rideseData.results.map((ride, i) => {
                const calcMinutes = Math.round(
                  (new Date(ride.datetime).getTime() - new Date().getTime()) /
                    (1000 * 60)
                );
                const isTrackDriver = calcMinutes <= 15;
                return (
                  <div
                    className="card shadow mb-3 grid-margin mx-3 p-3 mb-3"
                    style={{ border: "none" }}
                    key={i}
                  >
                    <div className="card-body">
                      <h5 className="mb-3">
                        <strong>Ride {ride.id}</strong>
                      </h5>
                      <div className="row">
                        <div className="col-md-4">
                          <p>
                            <strong>From</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.pickup_location}
                            </span>
                          </p>
                          <p>
                            <strong>To</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.drop_location}
                            </span>
                          </p>
                          <p>
                            <strong>Stops</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.stops &&
                              ride.stops.length > 0 &&
                              ride.stops[0].location_name ? (
                                <span>{ride.stops[0].location_name}</span>
                              ) : (
                                <span>No Stop</span>
                              )}
                            </span>
                          </p>
                          <p>
                            <strong>Booking Date & Time</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {stockholmDate(ride.created_date)}
                            </span>
                          </p>
                          <p>
                            <strong>Ride Date & Time</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {stockholmDate(ride.datetime)}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <strong>Rider Name</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.unregisterd_user_name}
                            </span>
                          </p>
                          <p>
                            <strong>Rider Phone</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.unregistered_user_phone}
                            </span>
                          </p>
                          <p>
                            <strong>Fare</strong>
                            <br />{" "}
                            <span className="text-dark">{ride.fare} S.E.K</span>
                          </p>
                          <p>
                            <strong>Distance</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.distance} KM
                            </span>
                          </p>
                          <p>
                            <strong>Company Name</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.company_name
                                ? ride.company_name
                                : "Company Not Assigned"}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <strong>Car Type</strong>
                            <br />{" "}
                            <span className="text-dark">{ride.car_type}</span>
                          </p>
                          <p>
                            <strong>Car Model</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.car_model
                                ? ride.car_model
                                : "Car Not Assigned"}
                            </span>
                          </p>
                          <p>
                            <strong>Car Registration Number</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.car_registration_number
                                ? ride.car_registration_number
                                : "Car Not Assigned"}
                            </span>
                          </p>
                          <p>
                            <strong>Driver Name</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {ride.driver_name
                                ? ride.driver_name
                                : "Driver Not Assigned"}
                            </span>
                          </p>
                          <p>
                            <strong>Driver Number</strong>
                            <br />{" "}
                            <span className="text-dark">
                              {" "}
                              {ride.driver_phone
                                ? ride.driver_phone
                                : "Driver Not Assigned"}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="mt-3">
                        <span
                          className="btn btn-outline-primary-border btn-sm mx-2 my-1"
                          style={{
                            color: "#6f35c7",
                            cursor: "default",
                            width: "auto",
                            padding: "9px",
                          }}
                        >
                          {ride.status}
                        </span>
                        <button
                          className="btn btn-outline-primary btn-small btn-sm mx-2 my-1"
                          onClick={() => handleMapOpen(ride)}
                        >
                          Route
                        </button>
                        {status == "cancelled" && (
                          <button
                            className="btn btn-outline-primary btn-small btn-sm mx-2 my-1"
                            onClick={() =>
                              refundHandleShowModel(
                                ride.refundId,
                                ride.refund_amount
                              )
                            }
                          >
                            Refund Status
                          </button>
                        )}

                        {status === "upcoming" && (
                          <>
                            <button
                              disabled={!isTrackDriver}
                              className="btn btn-outline-primary btn-small btn-sm mx-2 my-1"
                              style={{ color: "#6f35c7" }}
                              onClick={() =>
                                (window.location.href = `/live-tracking/${ride.id}`)
                              }
                            >
                              Track Driver
                            </button>
                            <button
                              className="btn btn-primary btn-sm mx-2 my-1"
                              onClick={() => handleShowModel(ride.id)}
                            >
                              Cancel
                            </button>
                          </>
                        )}

                        {(status === "upcoming" || status === "previous") && (
                          <button
                            className="btn btn-primary btn-sm my-1"
                            onClick={() => window.location.href=`${baseURL}/payment-reciept/${ride.id}/`}
                          >
                            Reciept
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="card shadow mb-5" style={{ border: "none" }}>
              <div className="card-body p-5">
                <h6 className="text-center">No Ride Found</h6>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="my-3">
        {status === "upcoming" && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={rideseData.total_pages}
            onPageChange={handlePageChange}
          />
        )}
        {status === "previous" && (
          <PaginationComponent
            currentPage={pcurrentPage}
            totalPages={rideseData.total_pages}
            onPageChange={handlpreviousPageChange}
          />
        )}
        {status === "cancelled" && (
          <PaginationComponent
            currentPage={cCurrentPage}
            totalPages={rideseData.total_pages}
            onPageChange={handlCancelPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default MyRide;
