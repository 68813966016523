import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Modal from '@mui/material/Modal';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';
import { baseURL } from '../../config';
import axios from 'axios';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const labels = {
  1: 'Useless',
  2: 'Poor',
  3: 'Ok',
  4: 'Good',
  5: 'Excellent',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}


const Review = ({openModal, rideId})=>{
  const [open, setOpen] = React.useState(openModal);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(1);
  const [hover, setHover] = React.useState(-1);
  const [btnText, setBtnText] = React.useState("Submit");
  
  const navigateToHomePage = ()=>{
    window.location.href = "/"
  }

  const submitReview = async () => {
    if (!value) {
      toast("All fields are mendetory");
      return;
    }
    const sessionKey = localStorage.getItem("key")
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionKey}`
      },
    };

    const data = {
      "rating": String(value),
      "tip": 0.0
    }

    try {
      setBtnText("submitting...")
      const resp = await axios.post(`${baseURL}/ride-rating/${rideId}`, data, options)
        .then(function (response) {
          toast("Review Submitted SucessFully");
          setBtnText("submitted")
          handleClose()
          navigateToHomePage()
          return response;
        });
    } catch (error) {
      setBtnText("submit")
      console.log(error)
      toast("Network Error");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" sx={{ mt: 2 }}>
            The journey has finished, and we would appreciate your feedback.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              sx={{
                width: 200,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                value={value}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
              {value !== null && (
                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
              )}
            </Box>
          </Typography>
          <Button onClick={()=>submitReview()} disabled={btnText!=="Submit"} variant="contained" style={{backgroundColor:'#663082', margin:"1rem 0"}}>{btnText}</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Review
