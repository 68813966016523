import { Stack } from "@mui/material";
import React from "react";
import Footer from "../components/Footer/Footer";
import MyRide from "../components/MyRides/MyRide";

const MyRidesPage = () => {
  return (
    <Stack>
      <MyRide />
      <Footer />
    </Stack>
  );
};

export default MyRidesPage;
