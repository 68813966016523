import MapComponent from "./MapComponent";

const MapInfoModal = ({ isOpen, onClose, title, origin, destination, via }) => {
    console.log(isOpen, onClose, origin, destination)
    if (!(isOpen && onClose && origin && destination)) return null
    return (
        <div className={`modal ${isOpen ? "d-block" : "d-none"}`} style={{
            position: "fixed",
            zIndex: 1050,
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        }} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{title}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"  onClick={() => onClose(false)}  aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <MapComponent
                            origin={origin}
                            destination={destination}
                            via={via}
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default MapInfoModal;