import { Stack } from "@mui/material";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";

const ContactPage = () => {
  return (
    <>
      <Stack>
        <Contact />
        <Footer />
      </Stack>
    </>
  );
};

export default ContactPage;
