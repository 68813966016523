import React from "react";
import styles from "./Download.module.css";
import appImg from "../../shared/images/groupImage2.png"
import appleDownload from "../../shared/images/pngwing.com - 2024-01-05T104917.765@2x.png"
import googleDownload from "../../shared/images/pngwing.com - 2024-01-05T104925.744@2x.png"
import { useTranslation } from "react-i18next";

const Download = () => {
  const {t, _} = useTranslation();
  return (
    <section className={`${styles.downloadSection}`} >
      <div className= 'row' >
        <div className="col-md-7" style={{textAlign:'center',paddingLeft:'4rem'}}>
          <div className={styles.downloadTextContainer}>
          <h1 className={styles.heading1}>{t("Get More Benefits")}</h1>
          <h2 className={styles.heading2}>{t("Download Our App")}</h2>
         <div className="mb-5 pb-3">
         <p className={styles.description}>
            {t("Experience modern travel without the hefty price tag. Our happy drivers make every journey beautiful from start to finish. Discover Sweden's latest taxi app that brings joy to every ride.")}
          </p>
          <p className={styles.description}>
            {t("Want to book a taxi right now? Simply download our user-friendly app and enjoy a seamless travel experience.")}
          </p>
         </div>
          <div className={styles.downloadBtn}>
            <a href="#" className="me-2" ><img  style={{marginRight:'3rem'}} src={googleDownload} className={styles.googleDownload}  alt="google" /></a>
           <a href="#"> <img  src={appleDownload} style={{marginRight:'3rem'}} className={styles.appleDownload} alt="apple" /></a>
          </div>
          </div>
        </div>
        <div className="col-md-5 ">
          <img src={appImg} className={styles.appImg} alt="appImg" />
        </div>
      </div>
    </section>
  );
};

export default Download;